<template>
  <div id="node">
    <div class="card">
      <div class="card-content" style="text-align: left;">
        <strong class="title is-5">
          <div class="blobs-container" style="float:right;">
            <div class="blob" :class="mqtt_status"></div>
          </div>
          <i class="fas fa-tablet-alt"></i>
          Device Number {{device.number}}
        </strong>
        <hr>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a @click="redirect('/member/mornitor')">สาขา</a></li>
            <li><a @click="redirect('/member/branch/'+node.b_id)">{{branch.name}}</a></li>
            <li><a @click="redirect('/member/node/'+device.n_id)">{{node.name}} <small class="ml-1 is-size-7">({{node.mac}})</small></a></li>
            <li class="is-active"><a>Device Number {{device.number}}</a></li>
          </ul>
        </nav>
      </div>
      <!-- is-desktop -->
      <div class="card m-3">
        <header class="card-header has-background-info">
         <p class="card-header-title has-text-white"> Phase A </p>
       </header>
        <div class="card-content">
          <div class="columns is-multiline is-touch">
            <div class="column is-one-quarter">
              <BlockData class="mx-3" title="Volt" :value="latestLog.Volt_a" unit="V" :created="latestLog.created"></BlockData>
            </div>
            <div class="column is-one-quarter">
              <BlockData class="mx-3" title="Current" :value="latestLog.Current_a" unit="A" :created="latestLog.created"></BlockData>
            </div>
            <div class="column is-one-quarter">
              <BlockData class="mx-3" title="Power" :value="latestLog.Power_a" unit="W" :created="latestLog.created"></BlockData>
            </div>
            <div class="column is-one-quarter">
              <BlockData class="mx-3" title="Power Factor" :value="latestLog.PF_a" unit="" :created="latestLog.created"></BlockData>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="card m-3">
        <header class="card-header has-background-info">
         <p class="card-header-title has-text-white"> Phase B </p>
       </header>
        <div class="card-content">
          <div class="columns is-multiline is-touch">
            <div class="column is-one-quarter">
              <BlockData class="mx-3" title="Volt" :value="latestLog.Volt_b" unit="V" :created="latestLog.created"></BlockData>
            </div>
            <div class="column is-one-quarter">
              <BlockData class="mx-3" title="Current" :value="latestLog.Current_b" unit="A" :created="latestLog.created"></BlockData>
            </div>
            <div class="column is-one-quarter">
              <BlockData class="mx-3" title="Power" :value="latestLog.Power_b" unit="W" :created="latestLog.created"></BlockData>
            </div>
            <div class="column is-one-quarter">
              <BlockData class="mx-3" title="Power Factor" :value="latestLog.PF_b" unit="" :created="latestLog.created"></BlockData>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="card m-3">
        <header class="card-header has-background-info">
         <p class="card-header-title has-text-white"> Phase C </p>
       </header>
        <div class="card-content">
          <div class="columns is-multiline is-touch">
            <div class="column is-one-quarter">
              <BlockData class="mx-3" title="Volt" :value="latestLog.Volt_c" unit="V" :created="latestLog.created"></BlockData>
            </div>
            <div class="column is-one-quarter">
              <BlockData class="mx-3" title="Current" :value="latestLog.Current_c" unit="A" :created="latestLog.created"></BlockData>
            </div>
            <div class="column is-one-quarter">
              <BlockData class="mx-3" title="Power" :value="latestLog.Power_c" unit="W" :created="latestLog.created"></BlockData>
            </div>
            <div class="column is-one-quarter">
              <BlockData class="mx-3" title="Power Factor" :value="latestLog.PF_c" unit="" :created="latestLog.created"></BlockData>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="card m-3">
        <header class="card-header has-background-link">
         <p class="card-header-title has-text-white"> Total </p>
       </header>
        <div class="card-content">
          <div class="columns is-multiline is-touch">
            <div class="column is-one-fifths">
              <BlockData class="mx-3" title="Total Power" :value="latestLog.Power_t" unit="W" :created="latestLog.created"></BlockData>
            </div>
            <div class="column is-one-fifths">
              <BlockData class="mx-3" title="Power Factor" :value="latestLog.PF_c" unit="" :created="latestLog.created"></BlockData>
            </div>
            <div class="column is-one-fifths">
              <BlockData class="mx-3" title="Frequency" :value="latestLog.Freq" unit="Hz" :created="latestLog.created"></BlockData>
            </div>
            <div class="column is-one-fifths">
              <BlockData class="mx-3" title="Img Energy" :value="latestLog.Imp_Energy" unit="Kwh" :created="latestLog.created"></BlockData>
            </div>
            <div class="column is-one-fifths">
              <BlockData class="mx-3" title="Exp Energy" :value="latestLog.Exp_Energy" unit="Kwh" :created="latestLog.created"></BlockData>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="columns is-desktop">
        <div class="column is-full">
          <line-chart :chart-data="chartData" :extra-options="extraOptions" style="height:375px;"></line-chart>
        </div>
      </div>
      <!-- <div>
        {{chartData}}
        {{device_log}}
        {{dataCollection}}
        <button @click="fillData()">Randomize</button>
      </div> -->
    </div>

  </div>
</template>

<script>
import BlockData from '@/components/BlockData'
import * as chartConfig from '@/components/Charts/chart.config'
import LineChart from '@/components/Charts/LineChart'

import moment from 'moment'

export default {
  name: 'Membernode',
  components:{ BlockData, LineChart },
  props: {
    loaded: Object,
    branchs: Array,
    nodes: Array,
    devices: Array,
    device_log: Array,
    client: Object,
    connected: Boolean,
  },
  data(){
    return {
      d_id: this.$route.params.id,
      branch: {},
      node: {},
      device: {},
      message: [],
      dataCollection: null,
      renderChart: true,
      modal_device: false,
      extraOptions: chartConfig.chartOptionsMain
    }
  },
  created(){
    const INDEX = this.devices.map((d) => parseInt(d.id)).indexOf(parseInt(this.d_id));
    if(INDEX!==-1){
      this.device = this.devices[INDEX];

      const INDEX_N = this.nodes.map((n) => parseInt(n.id)).indexOf(parseInt(this.device.n_id));
      if(INDEX_N!==-1){
        this.node = this.nodes[INDEX_N];

        const INDEX_B = this.branchs.map((b) => parseInt(b.id)).indexOf(parseInt(this.node.b_id));
        this.branch = this.branchs[INDEX_B];
      }else{
        this.redirect("/member/mornitor/")
      }
    }else{
      this.redirect("/member/mornitor/")
    }
  },
  mounted(){
    // console.log(this.loaded.get_device_log);
    if(!this.loaded.get_device_log){
      this.$emit('get_device_log',this.d_id);
    }else{
      const INDEX_ = this.loaded.get_device_log.map((d_id) => d_id).indexOf(this.d_id);
      if(INDEX_==-1){
        this.$emit('get_device_log',this.d_id);
      }
    }

    if(!parseInt(this.device.enable)){
      if(parseInt(this.device.id)){
        this.$swal({
          title: "อุปกรณ์นี้ถูกปิดใช้งาน!",
          text: "(Mac Address: "+this.node.mac+") \n (Device Number: "+this.device.number+")",
          className: "text-center",
          icon: "error",
        });
      }
    }else{
      if(this.client){
        // let self = this;
        // this.client.subscribe(TOPIC+self.node.mac, (err) => {
        //   self.connected = true;
        //   if (err){
        //     console.error('Error could not subscribe in '+TOPIC+self.node.mac+': '+ err.toString());
        //   }
        // });
        // // client.end();
        // // console.log("Disconnected: client.end");
        this.$emit("subscribeMQTT",this.node.mac);
      }else{
        // this.connectMQTT();
        this.$emit("connectMQTT",this.node.mac);
      }
    }
  },
  methods:{
    // connectMQTT(){
    //   if (typeof this.node.mac == 'undefined')return false;
    //   let self = this;
    //
    //   client = mqtt.connect(HOST);
    //   client.on('connect', function(){
    //     self.connected = true;
    //     console.log('Connected: successfully');
    //
    //     client.subscribe(TOPIC+self.node.mac);
    //     console.log(TOPIC+self.node.mac);
    //
    //     // // self.fillData();
    //     // // setInterval(()=> self.fillData(), 6000);
    //     // // test ////////////////////////////////////
    //     // setInterval(()=>{
    //     //   self.add_device_log(
    //     //     {
    //     //       d_id:self.d_id,
    //     //       number:self.device.number,
    //     //       Current:self.getRandomInt(),
    //     //       Freq:self.getRandomInt(),
    //     //       PF:self.getRandomInt(),
    //     //       Power:self.getRandomInt(),
    //     //       Volt:self.getRandomInt(),
    //     //       Energy:self.getRandomInt(),
    //     //       Key:"6c6756c82eca0bdd4c1340fa24d4f6081e906aeb"
    //     //     }
    //     //   )
    //     // }, 6000);
    //   });
    //   client.on('error', function (error) {
    //     self.connected = false;
    //     console.error(error);
    //   });
    //   client.on('message', function (topic, message) {
    //     // console.log(message.toString());
    //     self.validate_value(topic,message.toString());
    //   });
    // },
    // validate_value(topic,message){
    //   const MAC = topic.split('/').reverse()[0];
    //   const INDEX = this.nodes.map((n) => n.mac).indexOf(MAC);
    //   if(INDEX==-1){
    //     console.error("Unknow topic: ",MAC);
    //     return false;
    //   }
    //
    //   try {
    //     let json = JSON.parse(message);
    //     // console.log( json );
    //
    //     switch (true) {
    //       case (!json.Key):
    //         console.error("Not Found Key!!!");
    //         break;
    //       case (json.Key!=="6c6756c82eca0bdd4c1340fa24d4f6081e906aeb"):
    //         console.error("Invalid Key",json.Key);
    //         break;
    //       case (typeof json.ID=="undefined"):
    //         console.error("Invalid Value: ID",json);
    //         break;
    //       case (typeof json.Current=="undefined"):
    //         console.error("Invalid Value: Current",json);
    //         break;
    //       case (typeof json.Energy=="undefined"):
    //         console.error("Invalid Value: Energy",json);
    //         break;
    //       case (typeof json.Freq=="undefined"):
    //         console.error("Invalid Value: Freq",json);
    //         break;
    //       case (typeof json.PF=="undefined"):
    //         console.error("Invalid Value: PF",json);
    //         break;
    //       case (typeof json.Power=="undefined"):
    //         console.error("Invalid Value: Power",json);
    //         break;
    //       case (typeof json.Volt=="undefined"):
    //         console.error("Invalid Value: Volt",json);
    //         break;
    //       default:
    //         // console.log(json)
    //         this.add_device_log(json);
    //     }
    //   } catch (e) {
    //     console.error(e);
    //   }
    // },
    // add_device_log(json){
    //   const INDEX = this.devices.map((d) => parseInt(d.number)).indexOf(parseInt(json.ID));
    //   if(INDEX!==-1){
    //     const Device = this.devices[INDEX];
    //     if(!parseInt(Device.enable)){
    //       console.error("This device is disable!",Device.number);
    //       return false;
    //     }
    //     const d_id = Device.id;
    //
    //     Object.keys(json).forEach((key) => {
    //       json[key] = json[key].toString();
    //     });
    //     json.d_id = d_id;
    //     json.mac = this.node.mac;
    //     json.Number = json.ID;
    //     json.date_now = Date.now();
    //
    //     // console.log(json);
    //     this.device_log.unshift(json);
    //   }
    // },
    fillData () {
      this.renderChart = false;
      this.dataCollection = {
        labels: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
        datasets: [
          {
            label: "Data 1",
            borderColor: "#f87979",
            pointBackgroundColor: "#f87979",
            backgroundColor: "transparent",
            data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()]
          }, {
            label: "Data 2",
            borderColor: "#0080ff",
            pointBackgroundColor: "#0080ff",
            backgroundColor: "transparent",
            data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()]
          }
        ]
      }
    },
    getRandomInt () {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    submit_edit_device(input){
      console.log(input);
    },
  },
  computed:{
    mqtt_status(){
      return (!this.connected) ? "red" : "green"
    },
    thisDeviceLog(){
      return this.device_log.filter((log) => {
        return parseInt(log.d_id)==parseInt(this.device.id)
      });
    },
    latestLog(){
      let count = this.thisDeviceLog.length;
      if(parseInt(count)){
        return this.thisDeviceLog[0];
      }else {
        return {}
      }
    },
    chartData(){
      let limit = 24;
      let count = 0;
      let LABELS = [];
      let POWER_A = [];
      let POWER_T = [];
      let LastTimeLabel = null;
      let ThreePhase = false;
      this.thisDeviceLog.forEach((e) => {
        if(count<=limit){
          let TimeLabel = moment(new Date(parseInt(e.date_now))).format('h:mm A');
          if(LastTimeLabel!==TimeLabel){
            LABELS.unshift(TimeLabel);
            LastTimeLabel = TimeLabel;
          }else{
            LABELS.unshift("");
          }

          if(e.Power_t!=="-")ThreePhase=true;
          // ENERGY.unshift(e.Energy);
          POWER_A.unshift(e.Power_a);
          POWER_T.unshift(e.Power_t);
          count++;
        }
      });
      // .........................................................
      let CHART = {
        labels: LABELS,
        datasets: []
      };
      if(ThreePhase){
        CHART.datasets = [
          {
            label: "Total Power",
            borderColor: "#485fc7",
            pointBackgroundColor: "#485fc7",
            backgroundColor: "transparent",
            data: POWER_T
          }
        ]
      }else{
        CHART.datasets = [
          {
            label: "Power",
            borderColor: "#3e8ed0",
            pointBackgroundColor: "#3e8ed0",
            backgroundColor: "transparent",
            data: POWER_A
          }
        ]
      }
      return CHART
    }
  },
  watch:{
    renderChart(val){
      if(!val)setTimeout(() => this.renderChart=true, 1);
    },
  }
}
</script>

<style scoped="">
.blobs-container {
  display: flex;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  height: 12px;
  width: 12px;
  margin-top: 5px;
  transform: scale(1);
}

.blob.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.blob.green {
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
</style>
