<template>
  <!-- <div class="card">
    <header class="card-header">
      <p class="card-header-title">{{title}}</p>
    </header>
    <div class="card-content">
      <div class="content subtitle is-1">
        <b>{{value}}</b> {{unit}}
      </div>
    </div>
    <footer class="card-footer">
      <small class="w-100 has-text-centered">{{moment(created)}}</small>
    </footer>
  </div> -->
  <article class="message is-dark">
    <div class="message-header">
      <span class="is-5">{{title}}</span>
    </div>
    <div class="message-body py-2">
      <small class="title is-5"><b>{{value}}</b> {{unit}}</small>
    </div>
    <div class="messsage-footer">
      <small class="w-100 is-6 has-text-centered">{{moment(created)}}</small>
    </div>
  </article>
</template>

<script>
import moment from 'moment'

export default {
  name: 'BlockData',
  props: {
    title: String,
    value: String,
    unit: String,
    created: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "is-dark",
    }
  },
  methods: {
    moment(date) {
      if(date)return moment(date).format('Do MMM YYYY, h:mm A');
    }
  }
}
</script>
